import './App.css'
import Chamada from './pages/Chamada';

export default function App() {

  return (
    <div>
      <Chamada /> 
    </div>
  )
}
