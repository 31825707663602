import { useState } from "react";
import { Duvidas } from "./Duvidas";
import { IconContext } from "react-icons";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const Accordion = () => {
  const [clicked, setClicked] = useState(false);

  const toogle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <IconContext.Provider value={{ color: "#1d31cb", size: "25px" }}>
      <div className="m-2 md:p-2 md:mx-36 lg:mx-46">
        <div className="border rounded-md drop-shadow-sm p-2 mb-2 bg-blue-900 text-slate-200 font-bold text-center text-xl">
          PRINCIPAIS DÚVIDAS
        </div>
        <div className="border rounded-md mt-4 px-2 bg-slate-100">
          {Duvidas.map((item, index) => {
            return (
              <>
                <div
                  className="md:text-lg border rounded-md drop-shadow-sm flex justify-between  p-3 my-2 bg-white"
                  onClick={() => toogle(index)}
                  key={index}
                  >
                  <h1 className="">{item.title}</h1>
                  <span className="my-auto ">
                    {clicked === index ? <FiChevronUp /> : <FiChevronDown />}
                  </span>
                </div>
                  {clicked === index ? (
                  <div className="border  text-gray-700 rounded-md drop-shadow-sm mb-2 flex justify-between p-2 bg-white">
                    <p>{item.text}</p>
                  </div>
                ) : null}
              </>
            );
          })}
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default Accordion;


