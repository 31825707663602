import { useEffect } from "react";
import Comment from "../components/Comment";
import Accordion from "../components/Duvidas/Accordion";
import ReactPixel from 'react-facebook-pixel';

export default function Chamada() {
  ReactPixel.init('920544564994886');
  
  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view
    ReactPixel.trackCustom("page-mkt"); // For tracking custom'Cotacao2'); // For tracking custom . More info about custom : https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-
  }, []);

  return (
    <div className="flex flex-col flex-auto ">
      {/* <div
        className="w-screen"
        style={{
          backgroundImage: "url(assets/fundocaminhao.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      > */}
      <div className="bg-blue-900 ">
        <div className="bg-red-600 text-white p-3 shadow-md font-sans text-center w-screen text-sm">
          <h3>
            ETAPA 1 DE 2: ATENÇÃO, NÃO FECHE OU ATUALIZE ESSA PÁGINA ANTES DE
            GARANTIR SUA VAGA.
          </h3>
        </div>

        <div className="grow mx-auto px-4 lg:mx-20   ">
          <div className="flex flex-col md:px-10 ">
            <div className="text-center text-white ">
              <h1 className="p-2 text-xl font-bold">
                PARABÉNS!
                <br />
              </h1>
              <h1 className="text-xl">
                VOCÊ JÁ TEM ACESSO A UM DESCONTO PROMOCIONAL NA SUA PROTEÇÃO.
              </h1>
              <h2 className="py-2 text-xl">
                Assista agora o depoimento do Cristiano Sutilli, seu caminhão
                pegou fogo dando perca total, mas ele tinha Proteauto!
              </h2>
            </div>
            <div className="container mt-4 ">
              <iframe
                className="w-full aspect-video rounded shadow-md"
                src="https://www.youtube.com/embed/g9Y4xI7ngdw"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
            <div className="flex flex-col mx-auto m-4 ">
              <a
                href="https://cotacao.eficazrepresentacao.com.br/"
                id="proceed"
                className="bg-green-600  rounded-lg shadow-lg shadow-green-600/50 text-white text-base text-center p-3 my-4 cursor-pointer"
              >
                SIM, QUERO RECEBER UMA COTAÇÃO!
              </a>
            </div>
            {/* <a
                href=""
                id="proceed2"
                className="border border-red-800 text-slate-300 rounded-lg text-xs text-center my-4 mx-4 p-2"
              >
                NÃO QUERO FAZER A COTAÇÃO
              </a> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      <Comment />
      <Accordion />

      <div className="mx-auto m-4 flex flex-col">
        <div className="w-screen">
          <h1 className="text-center text-sm md:text-lg lg:text-2xl py-2 bg-slate-200  ">
            Faça agora uma simulação
            <span className="text-red-700 text-bold text-lg md:text-lg lg:text-2xl">
              {" "}
              sem compromisso!{" "}
            </span>
            Não demora nem 2 minutos!
          </h1>
        </div>

        <a
          href="https://cotacao.eficazrepresentacao.com.br/"
          id="proceed"
          className="bg-green-600  rounded-lg shadow-lg shadow-green-600/50 text-white text-base text-center p-3 m-4 md:w-1/4 mx-auto"
        >
          SIMULAR COTAÇÃO
        </a>
      </div>

      <footer className="bg-blue-800 text-white text-sm text-center p-2">
        © 2022 Eficaz Representação, todos os direitos reservados.
      </footer>

      {/* <script>
      $(document).ready(function () {
        setTimeout(function () {
          $("#proceed").show();
        }, 5000);
      });
      $(document).ready(function () {
        setTimeout(function () {
          $("#proceed2").show();
        }, 10000);
      });
    </script> */}
    </div>
  );
}
