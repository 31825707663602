

export const Duvidas = [
  { 
    title: "A proteção vem com rastreador?",
    text: "Sim, e para caminhões acima de 95 mil o rastreador é gratuito."
  },
  { 
    title: "A Proteauto cobre a partir de que ano?",
    text: "Veículos pesados a partir de 1981."
  },
  { 
    title: "Há preços especiais para frota?",
    text: "Sim, há condições especiais. "
  },
  { 
    title: "Tem representantes em quais locais do Brasil?",
    text: "A Proteauto conta com consultores em todos os estados do Brasil. Faça a cotação pelo site que será redirecionado para o consultor mais próximo de você. "
  },  
  { 
    title: "O que a Proteauto cobre?",
    text: "Não somente furto/roubo, danos a terceiros, colisão e guincho, mas cobre também implementos, rastreador, taxi, seguro de vida, destombamento, hospedagem, socorro elétrico e mecânico, assistência 24 horas. "
  },

]