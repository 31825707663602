export default function Comment() {
  const comments = [
    {
      perfil: "./assets/perfil/Sergio Se.jpg",
      name: "Sergio Delmenico ",
      depoiment:
        "Se não fosse a Proteauto hoje eu estaria quebrado, no primeiro ano tive um sinistro de perca total, no ano de 2019 tive dois sinistros e um roubo, da mesma forma me deram todo suporte e pagaram certinho. Eu confio na Proteauto, ela é minha parceira e eu sou parceiro dela.",
    },
    {
      perfil: "./assets/perfil/Ricardo sofri.jpg",
      name: "Ricardo (Madruga) ",
      depoiment:
        "Sofri um sinistro, onde colidi atrás de um caminhão caçamba. No primeiro atendimento com a Proteauto, eu liguei na assistência, deram todo suporte, o serviço e a reparação ficaram 100%, gostei, com certeza eu indico sim!",
    },
    {
      perfil: "./assets/perfil/Jesley Recebi.jpg",
      name: "Jesley Marques ",
      depoiment:
        "Recebi total suporte, da parte da Proteauto, o atendimento da global, a assistência da Eficaz também foi muito boa, a oficina muito responsável, fizeram tudo que estava ao alcance deles, tudo caminhando para uma excelente qualidade de serviço de todos da equipe!",
    },
    // {
    //   perfil: "./assets/perfil/Reinaldo Tombei.jpg",
    //   name: "Reinaldo (Nirdo) ",
    //   depoiment:
    //     "Tombei o caminhão saindo do pedágio, sem sinalização cai numa vala. O caminhão, não tenho o que reclamar, caminhão ficou melhor do que era, caminhão tá zero, não tenho o que reclamar !",
    // },
    // {
    //   perfil: "./assets/perfil/Ademir ja.jpg",
    //   name: "Ademir da Silva ",
    //   depoiment:
    //     "Já utilizei seguro contra terceiros, foi pago sem problemas, precisei de um Guincho e fui muito bem atendido, o guincho chegou rápidinho, me deixou no rercurso, não tenho o que reclamar não, no que precisei eles me atenderam certinho, eu indico a Proteauto!",
    // },
    // {
    //   perfil: "./assets/perfil/Antonio Eu.jpg",
    //   name: "Antonio Agostini ",
    //   depoiment:
    //     "Eu sofri um acidente durante uma viagem de caminhão, informei a Proteauto e fui muito bem atendido não tive que me preocupar com nada, eles que fizeram todo o trabalho, de buscar o guincho e levar o caminhão para outra cidade. Se você não é associado chegou a hora de ser.",
    // },
  ];

  return ( 
    <div className="m-2 rounded-lg ">
      <div className="mx-auto p-2 rounded-t-lg  md:p-4 md:mx-36 lg:mx-46 md:rounded-t-md bg-blue-900 overflow-hidden">
        <h1 className="text-center text-white	text-4xl font-semibold">DEPOIMENTOS</h1>
        <p className="text-center	text-white text-xl">o que dizem nossos clientes</p>
      </div>
      <div className="rounded-b-lg md:p-4 md:mx-36 lg:mx-46 md:rounded-b-md bg-slate-200 overflow-hidden">
        {comments.map((comment) => (
          <div>
            <div className="flex flex-row  p-2 ">
              <img
                className="w-16 h-16 p-2 rounded-full"
                src={comment.perfil}
              ></img>
              <div className="flex flex-col ">
                <div className="bg-slate-100 rounded-lg shadow-md p-3 text-xs md:text-md">
                  <span className="text-blue-900">{comment.name}</span>
                  {comment.depoiment}
                </div>
                <div className="p-2">
                  <span className="flex-col text-blue-900">
                    Like • Responder
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
